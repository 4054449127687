const databits = [
  {
    id:'HckEFxt8V9',
    zona: '',
    name: '',
    contacto: 'JAVIER ROMERO',
    email: '',
    movil: '099 714 2078',
    convencional: ''

  },



];
export default databits;
