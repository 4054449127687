const databits = [
  {
    id: 'FGjvkBofj9',
    zona: 'Norte - Av. Eloy Alfaro',
    name: 'PATERWIL IMPORT S.C.',
    contacto: 'PATERSON MIÑO',
    email: 'pmino@unilimpio.com',
    movil: '099 995 1615',
    convencional: '(02)280 5492'

  },
  {
    id: 'xVkfaT8Xxv',
    zona: 'Norte - Av. Occidental',
    name: '',
    contacto: 'DIMITRI CISNEROS',
    email: 'dcisneros@unilimpio.com',
    movil: '098 458 9666',
    convencional: ''

  },
  {
    id:'YTjdcjUCnj',
    zona: 'Centro - Norte',
    name: '',
    contacto: 'Irma Tacuri',
    email: 'wmoreira@unilimpio.com',
    movil: '097 970 8017',
    convencional: '(02)321 0455'

  },

  {
    id:'8Y5HxWPFw8',
    zona: 'Sector El Bosque',
    name: 'BIO&TOWER',
    contacto: 'Marcela Garzón',
    email: '',
    movil: '098 976 3341',
    convencional: ''

  },
  {
    id:'voVj1PD0RS',
    zona: 'Valles Norte (Cumbayá, Tumbaco, Pifo, Tababela)',
    name: 'LIMPIECITO',
    contacto: 'Galo Ochoa',
    email: 'gochoa@unilimpio.com',
    movil: '099 800 6661',
    convencional: ''

  },
 
 
  {
    id:'Y3krSl0QVI',
    zona: 'Valle de los chillos (sangolquí, amaguaña)',
    name: '',
    contacto: 'Victor Lima',
    email: 'vlima@unilimpio.com',
    movil: '099 074 7812',
    convencional: ''

  },

  {
    id:'9UIaEVL5FO',
    zona: 'Puembo, Pifo, Tababela',
    name: 'ECUADISTRIBUCIONES',
    contacto: 'Sandra Lamiña',
    email: 'ecuadistribuciones@yahoo.com',
    movil: '098 405 5123',
    convencional: '(02)273 0767'

  },


];
export default databits;
