const databits = [
  {
    id:'k6ZpWY7MfB',
    zona: 'Austro',
    name: 'UNILIMPIO SUR',
    contacto: 'ING. JUAN PABLO DAVILA',
    email: 'jpdavila@unilimpio.com',
    movil: '098 165 8917',
    convencional: ''

  },



];
export default databits;
