const databits = [
  {
    id:'a1Pjbf1lVu',
    zona: '',
    name: '',
    contacto: 'JESSICA GUTIERREZ',
    email: '',
    movil: '099 964 7168 ',
    convencional: '(07)293 9704, (07)293 9704'

  },



];
export default databits;
