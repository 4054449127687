const databits = [
 
  {
    id:'8NgkImI9Em',
    zona: 'Toda la ciudad de Guayaquil',
    name: 'BIOLIMPIO',
    contacto: 'CESAR CELLERI',
    email: 'info@biolimpio.net',
    movil: '099 296 6073',
    convencional: '099 893 7416, 099 0282 190'

  },
 
  {
    id:'NqKdeWXqvr',
    zona: 'Toda la ciudad de Guayaquil',
    name: 'VEBRACLEAN',
    contacto: 'ING. ALEX VEGA',
    email: 'vebraclean@hotmail.com',
    movil: '099 150 7929',
    convencional: '095 987 8449 '

  },


 



];
export default databits;
